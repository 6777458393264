import { render, staticRenderFns } from "./studentPersonalInfo.vue?vue&type=template&id=059de942&scoped=true&"
import script from "./studentPersonalInfo.vue?vue&type=script&lang=js&"
export * from "./studentPersonalInfo.vue?vue&type=script&lang=js&"
import style0 from "./studentPersonalInfo.vue?vue&type=style&index=0&id=059de942&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "059de942",
  null
  
)

export default component.exports