<!-- staffInfo -->
<template>
	<div class="content has_breadcrumb">
		<!-- <GgPageHeader
      :title="`${userInfo.firstName} ${userInfo.lastName}`"
      :menuData="['Reset password', 'Edit', 'Delete', 'Archive']"
      :subTitle1="userInfo.email"
      @menuCallback="handleMenuCallback"
    >
      <div slot="avatarHeader" class="avatar">
        <head-img
          class="imgBox"
          :firstName="userInfo.firstName"
          :lastName="userInfo.lastName"
          :src="userInfo.avatar"
          line-height="52"
        />
      </div>
      <div slot="info">
        <div class="title-status mar-b-0">{{ userInfo.status }}</div>
      </div>
    </GgPageHeader> -->
		<section-info1 :data="profileData.dataList" :renderPass="is_changed" title="Profile" 
		
		
		
		:iconEdit="pageType === '1' && !$route.query.class && ($buttonPermission('Staff', 'Full') ||
            $buttonPermission('Staff', 'Edit'))"
		 :readonly.sync="readonly" @submit="submitEditProfile" @cancel="handleCancelProfile" @readonly='changeReadonly'>
			<template slot="renderHtml" slot-scope="scope">
				<md-datepicker v-if="scope.data.key === 'dateOfBirth'" v-model="scope.data.value" :md-model-type="String"
				 md-immediately class="float datepicker-profile">
					<label>Date of birth</label>
				</md-datepicker>
				<!-- </div> -->
				<baseGGSelect v-else-if="scope.data.key === 'gender'" v-model="scope.data.value" :placeholder="scope.data.placeholder"
				 :key="scope.data.key" :ref="scope.data.key" class="float">
					<md-option v-for="(item, index) in scope.data.itemList" :key="index" :value="item">
						{{ item }}
					</md-option>
				</baseGGSelect>
				<baseGGSelect v-else-if="scope.data.key === 'nationality'" v-model="scope.data.value" :placeholder="scope.data.placeholder"
				 :key="scope.data.key" :ref="scope.data.key" class="float">
					<md-option v-for="(item, index) in scope.data.itemList" :key="index" :value="item">
						{{ item }}
					</md-option>
				</baseGGSelect>
				<baseGGSelect v-else-if="scope.data.key === 'residentialStatus'" v-model="scope.data.value" :placeholder="scope.data.placeholder"
				 :key="scope.data.key" :ref="scope.data.key" class="float">
					<md-option v-for="(item, index) in scope.data.itemList" :key="index" :value="item">
						{{ item }}
					</md-option>
				</baseGGSelect>

				<baseGGSelect v-else-if="scope.data.key === 'idType'" v-model="scope.data.value" :placeholder="scope.data.placeholder"
				 :key="scope.data.key" :ref="scope.data.key" class="float">
					<md-option v-for="(item, index) in scope.data.itemList" :key="index" :value="item">
						{{ item }}
					</md-option>
				</baseGGSelect>
				<baseGGSelect v-else-if="scope.data.key === 'race'" v-model="scope.data.value" :placeholder="scope.data.placeholder"
				 :key="scope.data.key" :ref="scope.data.key" class="float">
					<md-option v-for="(item, index) in scope.data.itemList" :key="index" :value="item">
						{{ item }}
					</md-option>
				</baseGGSelect>
				<PhoneNumber v-else-if="scope.data.key === 'phoneNumber'" v-model="scope.data.phoneNumberVal" :key="scope.data.key"
				 :ref="scope.data.key" class="float" @input="getPhoneNumber"></PhoneNumber>
				<baseGGSelect v-else-if="scope.data.key === 'jobTitle'" v-model="scope.data.value" :placeholder="scope.data.placeholder"
				 :key="scope.data.key" :ref="scope.data.key" class="float">
					<md-option v-for="(item, index) in scope.data.itemList" :key="index" :value="item">
						{{ item }}
					</md-option>
				</baseGGSelect>
				<baseGGSelect v-else-if="scope.data.key === 'workType'" v-model="scope.data.value" :placeholder="scope.data.placeholder"
				 :key="scope.data.key" :ref="scope.data.key" class="float">
					<md-option v-for="(item, index) in scope.data.itemList" :key="index" :value="item">
						{{ item }}
					</md-option>
				</baseGGSelect>
			</template>
		</section-info1>
		<Section title="Home address" class="margin-0">
			<div class="section-content">
				<ul class="section-content-input" v-if="homeAddress.readonly">
					<li class="item" :class="{ edit: !homeAddress.readonly }">
						<div class="label">Address</div>
						<div class="value">
						
							<div v-if="homeAddress.address">
								<div>{{ homeAddress.address }}</div>
							</div>
							<div v-else>-</div>
							<div v-if="homeAddress.addressLine2">
								<div>{{ homeAddress.addressLine2 }}</div>
							</div>
							<div v-else>-</div>
							<div v-if="homeAddress.postalCode">
								<div>{{ homeAddress.postalCode }}</div>
							</div>
							<div v-else>-</div>
						</div>
						<div class="label"></div>
						<div class="value"></div>
					</li>
					<li class="item" :class="{ edit: !homeAddress.readonly }">
						<div class="label">Home number</div>
						<div class="value">
							<div v-if="homeAddress.homeNumber">
								{{homeAddress.homeCountryCode}} {{ homeAddress.homeNumber }}
							</div>
							<div v-else>-</div>
						</div>
						<div class="label"></div>
						<div class="value"></div>
					</li>
				</ul>
				<div v-if="!homeAddress.readonly" class="input-content">
					<gg-input v-model="homeAddress.address" key="address" ref="address" placeholder="Address" class="float" />
					<gg-input v-model="homeAddress.addressLine2" key="addressLine2" ref="addressLine2" placeholder="Address line 2"
					 class="float" />
					<gg-input v-model="homeAddress.postalCode" key="postalCode" ref="postalCode" placeholder="Postal code" class="float" />
					<PhoneNumber v-model="homeAddress.phone" ref="homeNumber" class="float" @input='getHomeNumber'></PhoneNumber>
				</div>
				<i v-if="homeAddress.readonly && pageType === '1' && !$route.query.class && ($buttonPermission('Staff', 'Full') ||
            $buttonPermission('Staff', 'Edit'))" class="iconfont iconedit" @click="editHomeAddress"></i>
				<div v-if="!homeAddress.readonly" class="text-right">
					<BaseButton @click="cancelUpdateStaff">Cancel</BaseButton>
					<BaseButton @click="submitUpdateStaff" type="primary" :disabled='!is_homeChanged'>Save</BaseButton>
				</div>
			</div>
		</Section>
		<!-- view locations -->
		<gg-Dialog :append-to-body="false" :visible.sync="formDialogLocations.viewLocations" :show-close="true" title="Locations">
			<div class="dialog-content">
				<CheckboxGroup :checkTitle="formDialogLocations.checkTitle" :checkedData="formDialogLocations.checkedData"
				 :checkboxList="formDialogLocations.checkboxList" @check="handleCheck" />
			</div>
		</gg-Dialog>
		<!-- resetpassword dialog -->
		<gg-Dialog :visible.sync="formDialogPwd.visible" :before-close="cancelResetPwd" width="580px" ref="resetPassword">
			<div slot="title">
				Reset password for
				<span class="title-bold">{{ formDialogPwd.name }}</span>
			</div>
			<div>
				<ResetPassword @sendEmail="resetByEmail" :autoSend="formDialogPwd.autoSend" v-model="formDialogPwd.data"></ResetPassword>
			</div>
			<span slot="footer" class="dialog-footer">
				<gg-cancel-button @click="cancelResetPwd">Cancel</gg-cancel-button>
				<gg-submit-button :disabled="formDialogPwd.isDisabled" @click="handleResetPwd">Reset</gg-submit-button>
			</span>
		</gg-Dialog>
		<Snackbar :visible.sync="snackbarData.visible" :content="snackbarData.content" />
	</div>
</template>

<script>
	import {
		Ajax
	} from "@/common";
	import {
		parse
	} from "date-fns/esm";
	import {
		lightFormat
	} from "date-fns/esm/fp";
	export default {
		components: {},
		watch: {
			profileData: {
				deep: true,
				handler(res) {
					this.$nextTick(() => {
						this.profileData.dataList.forEach((item,index) => {
							if(item.key == "phoneNumber"){
								   item.value = `${item.phoneNumberVal.countryCode} ${item.phoneNumberVal.phoneNumber}`;
							  } 
							  if(this.is_started){
								 if (this.firstData[index].value == item.value) {
								 } else {
								 	this.is_changed = true
								 	sessionStorage.setItem('isChanged', JSON.stringify(this.is_changed || this.is_homeChanged))
								 } 
							  }
							
						});
						let telPass =
							this.$refs.phoneNumber &&
							!this.$refs.phoneNumber.isError.show &&
							!!this.$refs.phoneNumber.value.phoneNumber;
						this.profileData.renderPass = telPass;
					console.log("changed",this.is_changed)
					});
				},
			},
			homeAddress: {
				deep: true,
				handler(res) {
					this.$nextTick(() => {
						res.homeNumber = `${res.phone.phoneNumber}`;
						res.homeCountryCode = `${res.phone.countryCode}`;
						let telPass =
							this.$refs.homeNumber &&
							!this.$refs.homeNumber.isError.show &&
							!!this.$refs.homeNumber.value.phoneNumber;
						this.is_HomePass = !telPass;
						// console.log()
						if (this.homeAddress.address == this.firstHomeData.address &&
							this.homeAddress.addressLine2 == this.firstHomeData.addressLine2 &&
							this.homeAddress.postalCode == this.firstHomeData.postalCode &&
							this.homeAddress.phone.countryCode == this.firstHomeData.phone.countryCode &&
							this.homeAddress.phone.phoneNumber == this.firstHomeData.phone.phoneNumber
						) {} else {
							this.is_homeChanged = true
							sessionStorage.setItem('isChanged', JSON.stringify(this.is_changed || this.is_homeChanged))
						}
						console.log(this.is_homeChanged)
					});
				},
			},
		},
		data() {
			return {
				is_HomePass: false,
				slotData: ["country", "tel"],
				companyId: "",
				query: {
					current: 1,
					size: 10,
				},
				breadcrumbData: [],
				activeIndex: "personalInfo",
				// userInfo: {
				//   avatar: "",
				//   firstName: "",
				//   lastName: "",
				//   email: "",
				//   status: "",
				// },
				formDialogLocations: {
					viewLocations: false,
					checkTitle: "Raffles Kidz International",
					checkedData: [],
					checkboxList: [],
				},
				profileData: {
					renderPass: true,
					formData: {},
					readonly: true,
					dataList: [{
							placeholder: "Date of birth",
							key: "dateOfBirth",
							value: null,
							float: true,
							renderHtml: true,
						},
						{
							placeholder: "Gender",
							key: "gender",
							value: "",
							itemList: [],
							renderHtml: true,
						},
						{
							placeholder: "Nationality",
							key: "nationality",
							value: "",
							itemList: [],
							renderHtml: true,
						},
						{
							placeholder: "Residential status",
							key: "residentialStatus",
							value: "",
							itemList: [],
							renderHtml: true,
						},
						{
							placeholder: "ID type",
							key: "idType",
							value: "",
							itemList: [],
							renderHtml: true,
						},
						{
							placeholder: "ID no.",
							key: "idNo",
							value: "",
							float: true,
						},
						{
							placeholder: "Race",
							key: "race",
							value: "",
							itemList: [],
							renderHtml: true,
						},
						{
							placeholder: "Phone",
							key: "phoneNumber",
							value: "",
							phoneNumberVal: {
								countryCode: "+65",
								phoneNumber: "",
							},
							float: true,
							renderHtml: true,
						},
						{
							placeholder: "Working title",
							key: "jobTitle",
							value: "",
							itemList: [],
							renderHtml: true,
						},
						{
							placeholder: "Working type",
							key: "workType",
							value: "",
							itemList: [],
							renderHtml: true,
						},
					],
				},
				homeAddress: {
					readonly: true,
					address: "",
					addressLine2: "",
					postalCode: "",
					homeNumber: "",
					phone: {
						countryCode: "+65",
						phoneNumber: "",
					},
				},
				formDialogPwd: {
					visible: false,
					data: "",
					autoSend: true,
					name: "",
					id: "",
					isDisabled: false,
				},
				snackbarData: {
					visible: false,
					content: "",
				},
				readonly: true,
				// 0217cy  start 这个参数用来判断当前表单是否修改  在getData里重置为false watch里监听改为true 控制点击编辑按钮时 是否弹窗
				is_started:false, //此参数用来避免初始化数据监控多次判断
				is_changed: false,
				is_homeChanged: false,
				firstData: [{
							placeholder: "Date of birth",
							key: "dateOfBirth",
							value: null,
							float: true,
							renderHtml: true,
						},
						{
							placeholder: "Gender",
							key: "gender",
							value: "",
							itemList: [],
							renderHtml: true,
						},
						{
							placeholder: "Nationality",
							key: "nationality",
							value: "",
							itemList: [],
							renderHtml: true,
						},
						{
							placeholder: "Residential status",
							key: "residentialStatus",
							value: "",
							itemList: [],
							renderHtml: true,
						},
						{
							placeholder: "ID type",
							key: "idType",
							value: "",
							itemList: [],
							renderHtml: true,
						},
						{
							placeholder: "ID no.",
							key: "idNo",
							value: "",
							float: true,
						},
						{
							placeholder: "Race",
							key: "race",
							value: "",
							itemList: [],
							renderHtml: true,
						},
						{
							placeholder: "Phone",
							key: "phoneNumber",
							value: "",
							phoneNumberVal: {
								countryCode: "+65",
								phoneNumber: "",
							},
							float: true,
							renderHtml: true,
						},
						{
							placeholder: "Working title",
							key: "jobTitle",
							value: "",
							itemList: [],
							renderHtml: true,
						},
						{
							placeholder: "Working type",
							key: "workType",
							value: "",
							itemList: [],
							renderHtml: true,
						},
					],
				firstHomeData: {},
			};
		},
		computed: {
			userId() {
				return JSON.parse(sessionStorage.getItem("staffInfo")).userId;
			},
			pageType() {
				return JSON.parse(sessionStorage.getItem("staffInfo")).type;
			},
		},
		beforeRouteLeave(to, from, next) {
			if(this.is_changed || this.is_homeChanged){
				let messageObj = {
					title: "Save changes",
					desc: "You have some unsaved changes.Please save them before you continue.",
					cancelTxt: "",
					confirmTxt: "",
				};
				let callback = () => {
					// this.handleUpdateClass("delete");
				};
				this.openMessageBox(messageObj, callback);
			}else{
				next()
			}
		},
		methods: {
			editStaff() {
				if (!this.is_changed) {
					this.readonly = true
					this.homeAddress.readonly = false;
				} else {
					let messageObj = {
						title: "Save changes",
						desc: "You have some unsaved changes.Please save them before you continue.",
						cancelTxt: "",
						confirmTxt: "",
					};
					let callback = () => {
						// this.handleUpdateClass("delete");
					};
					this.openMessageBox(messageObj, callback);
				}
			},
			cancelUpdateStaff() {
				this.getData()
				this.homeAddress.readonly = true;
			},
			submitUpdateStaff() {
				let params = {
					address: this.homeAddress.address,
					addressLine2: this.homeAddress.addressLine2,
					postalCode: this.homeAddress.postalCode,
					homeCountryCode: this.homeAddress.phone.countryCode,
					homeNumber: this.homeAddress.phone.phoneNumber,
					userId: this.userId,
				};
				Ajax.post("/usermanage/staff/updateStaff", params).then((res) => {
					if (res.code === "0000") {
						this.homeAddress.readonly = true;
						this.handleSnack("Staff has been edited");
						this.getData();
					}
				});
			},
			// Reset password', 'Edit', 'Delete', 'Archive
			// handleMenuCallback(item) {
			//   if (item === "Reset password") {
			//     this.openDialogPwd();
			//   }
			//   if (item === "Edit") {
			//     console.log("edit");
			//   }
			//   if (item === "Delete") {
			//     this.$confirm(
			//       "Once deleted, the teacher will not be able to access the app.",
			//       "Delete teacher ?",
			//       {
			//         cancelButtonText: "Cancel",
			//         confirmButtonText: "Delete",
			//         cancelButtonClass: "gg_cancel_button",
			//         confirmButtonClass: "gg_submit_button",
			//         customClass: "gg_confirm",
			//       }
			//     )
			//       .then(() => {
			//         Ajax.post("/usermanage/staff/updateStatus", {
			//           companyId: this.companyId,
			//           userId: this.userId,
			//           status: "delete",
			//         })
			//           .then((res) => {
			//             if (res.code === "0000") {
			//               this.getData();
			//               this.handleSnack("Staff has been deleted");
			//             }
			//           })
			//           .catch(() => {});
			//       })
			//       .catch(() => {});
			//   }
			//   if (item === "Archive") {
			//     this.$confirm(
			//       "Once archived, the staff will move to archived.",
			//       "Archive staff ?",
			//       {
			//         cancelButtonText: "Cancel",
			//         confirmButtonText: "Archive",
			//         cancelButtonClass: "gg_cancel_button",
			//         confirmButtonClass: "gg_submit_button",
			//         customClass: "gg_confirm",
			//       }
			//     )
			//       .then(() => {
			//         Ajax.post("/usermanage/staff/updateStatus", {
			//           companyId: this.companyId,
			//           userId: this.userId,
			//           status: "archive",
			//         })
			//           .then((res) => {
			//             if (res.code === "0000") {
			//               this.getData();
			//               this.handleSnack("Staff has been archived");
			//             }
			//           })
			//           .catch(() => {});
			//       })
			//       .catch((err) => {
			//         console.log(err);
			//       });
			//   }
			// },
			handleSnack(content) {
				this.snackbarData.content = content;
				this.snackbarData.visible = true;
			},
			resetByEmail(val) {
				this.formDialogPwd.autoSend = val;
				if (val || this.formDialogPwd.data.length > 8) {
					this.formDialogPwd.isDisabled = false;
				} else {
					this.formDialogPwd.isDisabled = true;
				}
			},
			openDialogPwd() {
				// this.formDialogPwd.name = row.name;
				this.formDialogPwd.id = this.userId;
				this.formDialogPwd.autoSend = true;
				this.formDialogPwd.isDisabled = false;
				this.formDialogPwd.visible = true;
			},
			cancelResetPwd() {
				this.formDialogPwd.visible = false;
				this.formDialogPwd.data = "";
			},
			handleResetPwd() {
				let isAuto = this.formDialogPwd.autoSend ? "1" : "0";
				this.formDialogPwd.visible = false;
				Ajax.post("/usermanage/password/resetPasswd", {
						userId: this.formDialogPwd.id,
						newPasswd: this.formDialogPwd.data,
						isAuto: isAuto,
					})
					.then((res) => {
						if (res.code === "0000") {
							this.handleSnack("Password has been reset");
							this.formDialogPwd.data = "";
						}
					})
					.catch((err) => {
						console.log(err);
					});
			},
			getData() {
				let params = {
					id: this.userId,
				};

				Ajax.get("/usermanage/staff/user/selectStaffByUserId", params)
					.then((res) => {
						if (res.code === "0000") {
							let {
								firstName,
								lastName,
								photo,
								emailAddress,
								status,
								locations,
								companyId,
								address,
								addressLine2,
								postalCode,
								countryCode,
								phoneNumber,
								homeNumber,
								homeCountryCode,
							} = res.data;
							this.companyId = companyId;
							this.firstHomeData = {};
							this.firstHomeData.address = address;
							this.firstHomeData.addressLine2 = addressLine2;
							this.firstHomeData.postalCode = postalCode;
							this.firstHomeData.phone = {
								countryCode: homeCountryCode ? homeCountryCode : "+65",
								phoneNumber: homeNumber ? homeNumber : "",
							};
							this.homeAddress.address = address;
							this.homeAddress.addressLine2 = addressLine2;
							this.homeAddress.postalCode = postalCode;

							this.homeAddress.phone = {
								countryCode: homeCountryCode ? homeCountryCode : "+65",
								phoneNumber: homeNumber ? homeNumber : "",
							};
							
							this.firstData = []
							this.is_changed = false
							this.is_homeChanged = false
							this.homeAddress.readonly = true;
							this.readonly = true;
							let a 
							this.profileData.dataList.forEach((item) => {
								if (res.data[item.key]) {
									item.value = res.data[item.key];
								}
								if (item.key === "phoneNumber") {
									
									item.phoneNumber = "";
									item.phoneNumberVal = {
										countryCode: res.data["countryCode"] ?
											res.data["countryCode"] :
											"+65",
										phoneNumber: res.data["phoneNumber"] ?
											res.data["phoneNumber"] :
											"",
									};
									  item.value = `${item.phoneNumberVal.countryCode} ${item.phoneNumberVal.phoneNumber}`;
								}
								a = {
									'key': item.key,
									'value': item.value
								}
								this.firstData.push(a)
							});   
							sessionStorage.setItem('isChanged', JSON.stringify(this.is_changed || this.is_homeChanged))
							this.is_started=true
							// this.formDialogLocations.checkedData = locations;
						}
					})
					.catch((err) => {
						console.log(err);
					});
			},
			handleCheck(val) {
				this.formDialogLocations.checkedData = val;
			},
			getPhoneNumber(res) {
				this.profileData.dataList.forEach((item) => {
					if (item.key === "phoneNumber") {
						item.phoneNumber = "";
						item.phoneNumberVal = {
							countryCode: res.countryCode,
							phoneNumber: res.phoneNumber
						};
					}
				});
			},
			getHomeNumber(res) {
				this.homeAddress.phone.countryCode = res.countryCode
				this.homeAddress.phone.phoneNumber = res.phoneNumber
			},
			handleCancelProfile() {
				// this.readonly = true;
				this.getData()
			},
			submitEditProfile() {
				this.profileData.formData = {};
				this.profileData.dataList.forEach((i) => {
					this.profileData.formData[i.key] = i.value;
					if (i.key === "phoneNumber") {
						 this.profileData.formData[i.key]=i.phoneNumberVal.phoneNumber
						this.profileData.formData.countryCode = i.phoneNumberVal.countryCode
					}
				});
				this.profileData.formData.userId = this.userId;
				Ajax.post(
					"/usermanage/staff/updateStaff",
					this.profileData.formData
				).then((res) => {
					if (res.code === "0000") {
						this.readonly = true;
						this.handleSnack("Staff has been edited");
						this.getData();
					}
				});
			},
			handleCancelHomeAddress() {
				// this.readonly = true;
			},
			submitEditHomeAddress() {},
			getLocation() {
				let params = {
					userId: this.userId,
				};

				Ajax.get("/usermanage/location/selectLocationByUserId", params).then(
					(res) => {
						if (res.code === "0000") {
							res.data.map((item) => {
								// this.formDialogLocations.checkboxList.push(item)
							});
						}
					}
				);
			},
			getSettingList(dataLevel, dataType) {
				let params = {
					dataLevel: dataLevel,
					dataType: dataType,
					userId: this.userId,
				};

				Ajax.post("/usermanage/setting/getList", params)
					.then((res) => {
						if (res.code === "0000") {
							let arr = [];
							res.data.forEach((item1) => {
								this.profileData.dataList.forEach((item2) => {
									if (item1.dataType === item2.key) {
										item2.itemList.push(item1.value);
									}
								});
							});
						}
					})
					.catch((err) => {
						console.log(err);
					});
			},
			editHomeAddress() {
				if (!this.is_changed) {
					this.readonly = true
					this.homeAddress.readonly = false;
				} else {
					let messageObj = {
						title: "Save changes",
						desc: "You have some unsaved changes.Please save them before you continue.",
						cancelTxt: "",
						confirmTxt: "",
					};
					let callback = () => {
						// this.handleUpdateClass("delete");
					};
					this.openMessageBox(messageObj, callback);
				}
			
			},
			changeReadonly() {
				if (!this.is_homeChanged) {
					this.readonly = false
					this.homeAddress.readonly = true;
				} else {
					let messageObj = {
						title: "Save changes",
						desc: "You have some unsaved changes.Please save them before you continue.",
						cancelTxt: "",
						confirmTxt: "",
					};
					let callback = () => {
						// this.handleUpdateClass("delete");
					};
					this.openMessageBox(messageObj, callback);
				}
			},
			 openMessageBox(messageObj, callback) {
      this.$confirm(messageObj.desc, messageObj.title, {
        cancelButtonText: messageObj.cancelTxt,
        confirmButtonText: messageObj.confirmTxt,
        cancelButtonClass: "gg_cancel_button",
        confirmButtonClass: "gg_submit_button",
        customClass: "gg_confirm",
		showCancelButton: false, // 不显示取消按钮
		showConfirmButton: false,
        callback: (action) => {
          if (action == "confirm") {
            callback(action);
          }
        },
      }).then(() => {
        this.handleSuspend(messageObj.snackbarContent);
      });
    },
		},
		created() {
			this.$material.locale.dateFormat = "dd/MM/yyyy";
			this.breadcrumbData = this.$store.state.breadcrumb.breadcrumbData;
			this.getData();
			this.getLocation();
			this.getSettingList("staff", "gender");
			this.getSettingList("general", "nationality");
			this.getSettingList("general", "residentialStatus");
			this.getSettingList("staff", "idType");
			this.getSettingList("general", "race");
			this.getSettingList("staff", "workType");
			this.getSettingList("staff", "jobTitle");
		},
	};
</script>
<style lang='less' scoped>
	//@import url(); 引入公共css类
	/deep/ .phone-number-content {
		padding-bottom: 0;
		padding-top: 20px;
	}

	/deep/ .section-main {
		padding: 0;
	}

	/deep/ .el-dialog__body {
		max-height: 412px;
		overflow-y: auto;
	}

	/deep/.datepicker-profile {
		.md-icon-image {
			position: absolute;
			right: 0;
			margin: 2px auto 0;
		}

		/deep/.md-clear {
			right: 24px !important;
			top: auto;
			bottom: 3px;

			/deep/.md-icon-image {
				position: relative;
				right: auto;
				margin: 4px auto;
			}
		}

		/deep/ .md-input {
			margin-left: 0;
			color: #202124;
			font-family: Roboto;
		}

		/deep/ label {
			left: 0;
			font-size: 14px;
			color: #b4b4b4;
			font-family: Roboto;
		}
	}
	/deep/.md-field{
		    margin-bottom: 15px;
	}
	/deep/.section-content li.item{
		font-family: Roboto;
	}
	/deep/.section-info-main{
		font-family: Roboto;
	}
	/deep/.section-content li.item .label{
		font-family: Roboto-medium;
	}
	/deep/.section-info-main .readonly-content .item .label{
		font-family: Roboto-medium;
	}
	/deep/.md-field label{
		left: 0;
		color: rgba(0,0,0,0.3);
		font-size: 14px !important;
	}
	/deep/.md-field .md-input{
		font-family: Roboto;
		margin-left: 0;
		font-size: 14px !important;
	}
</style>
