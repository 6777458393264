<!-- Privileges -->
<template>
  <div class="content has_breadcrumb" style="padding-bottom: 112px;">
    <!-- <GgPageHeader
      :userInfo="userInfo"
      :menuData="['Reset password', 'Edit', 'Delete', 'Archive']"
      :title="userInfo.title"
      :subTitle1="userInfo.subTitle1"
      @_uploadCallbackLogo="_uploadCallbackLogo"
    >
      <div slot="title-label" class="title-label" @click="viewLocations = true">
        <span>{{ viewLabel }}</span>
        <img
          v-if="checkedData.length > 1"
          class="icon-view"
          src="@/assets/images/icons/icon-view.png"
          alt=""
        />
      </div>
      <div slot="subTitleLabel" class="title-label">Teacher</div>
      <div slot="info">
        <div class="title-status mar-b-0">{{ userInfo.status }}</div>
      </div>
    </GgPageHeader> -->
    <Section title="Admin Console" class="mar-b-20">
      <ul class="admin-console">
        <li>
          <div class="admin-console-header">
            <img
              class="admin-console-header-icon"
              :src="require('@/assets/images/menu/adminconsole.svg')"
              alt=""
            />
            Admin console
          </div>
          <ul class="admin-console-list">
            <li>
              <img
                class="admin-console-header-icon"
                :src="require('@/assets/images/menu/settings.svg')"
                alt=""
              />
              Settings
            </li>
            <li>
              <img
                class="admin-console-header-icon"
                :src="require('@/assets/images/menu/settings.svg')"
                alt=""
              />
              Roles
            </li>
          </ul>
        </li>
      </ul>
    </Section>
    <Section title="Privileges" class="section-nopad">
      <ul class="privileges-default">
        <li v-for="(item, index) in formDataPrivilege" :key="index">
          <div class="privileges-default-header">
            <img
              class="privileges-default-header-icon"
              :src="item.apps"
              alt=""
            />
            <i class="iconfont iconarrow_drop_down-24px" />
            <md-checkbox
              :disabled="!isEdit"
              class="md-primary"
              v-model="item.ismodulePermission"
              >{{ item.moduleName }}</md-checkbox
            >
          </div>
          <ul class="privileges-default-list">
            <li
              v-for="(privilege, subIndex) in item.privilegeList"
              :key="subIndex"
            >
              <div class="privileges-default-space"></div>
              <div class="privileges-default-role">
                {{ privilege.name }}
              </div>
              <div
                class="privileges-default-describcontent"
                v-if="item.ismodulePermission"
              >
                <div class="privileges-default-privilege">
                  {{privilege.optionsVal}}
                  <!-- <baseGGSelect
                    :disabled="!isEdit"
                    v-model="privilege.optionsVal"
                    :inline="true"
                  >
                    <md-option
                      v-for="option in privilege.options"
                      :key="option"
                      :value="option"
                    >
                      {{ option }}
                    </md-option>
                  </baseGGSelect> -->
                </div>

                <el-tooltip
                  v-if="privilege.optionsVal === 'View'"
                  :content="`${privilege.moduleDesc['View']}`"
                  popper-class="edoovo-table-cell-tooltip"
                  :visible-arrow="false"
                >
                  <div class="privileges-default-desc">
                    {{ `${privilege.moduleDesc["View"]}` }}
                  </div>
                </el-tooltip>
                <el-tooltip
                  v-else-if="privilege.optionsVal === 'Create'"
                  :content="`${privilege.moduleDesc['View']}, ${privilege.moduleDesc['Create']}`"
                  popper-class="edoovo-table-cell-tooltip"
                  :visible-arrow="false"
                >
                  <div class="privileges-default-desc">
                   {{
                     `${privilege.moduleDesc["Create"]?`${privilege.moduleDesc["View"]}, ${privilege.moduleDesc["Create"]}`:`${privilege.moduleDesc["View"]}`}`
                   }}
                  </div>
                </el-tooltip>
                <el-tooltip
                  v-else-if="privilege.optionsVal === 'Edit'"
                  :content="`${privilege.moduleDesc['View']}, ${privilege.moduleDesc['Create']}, ${privilege.moduleDesc['Edit']}`"
                  popper-class="edoovo-table-cell-tooltip"
                  :visible-arrow="false"
                >
                  <div class="privileges-default-desc">
                   {{
                     `${privilege.moduleDesc["Create"]?`${privilege.moduleDesc["View"]}, ${privilege.moduleDesc["Create"]}, ${privilege.moduleDesc["Edit"]}`
                     :`${privilege.moduleDesc["View"]}, ${privilege.moduleDesc["Edit"]}`}`
                   }}
                  </div>
                </el-tooltip>
                <el-tooltip
                  v-else-if="privilege.optionsVal === 'Full'"
                  :content="`${privilege.moduleDesc['View']}, ${privilege.moduleDesc['Create']}, ${privilege.moduleDesc['Edit']}, ${privilege.moduleDesc['Full']}`"
                  popper-class="edoovo-table-cell-tooltip"
                  :visible-arrow="false"
                >
                  <div class="privileges-default-desc">
                   {{
                     `${privilege.moduleDesc["Create"]?`${privilege.moduleDesc["View"]}, ${privilege.moduleDesc["Create"]}, ${privilege.moduleDesc["Edit"]}, ${privilege.moduleDesc["Full"]}`
                     :`${privilege.moduleDesc["View"]}, ${privilege.moduleDesc["Edit"]}`}`
                   }}
                  </div>
                </el-tooltip>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </Section>
    <!-- </GgPage> -->
    <!-- view locations -->
    <!-- <gg-Dialog
      :append-to-body="false"
      :visible.sync="viewLocations"
      :show-close="true"
      title="Locations"
    >
      <div class="dialog-content">
        <CheckboxGroup
          :checkTitle="checkTitle"
          :checkedData="checkedData"
          :checkboxList="checkboxList"
          @check="handleCheck"
        />
      </div>
    </gg-Dialog> -->
  </div>
</template>

<script>
import { Ajax } from "@/common";
import { lightFormat } from "date-fns";
export default {
  components: {},
  data() {
    return {
      activeIndex: "personalInfo",
      isEdit: false,
      roleId:"",
      // userInfo: {
      //   title: "",
      //   avatar: "",
      //   firstName: "",
      //   lastName: "",
      //   subTitle1: "",
      // },
      // viewLocations: false,
      // checkTitle: "Raffles Kidz International",
      // checkedData: ["Yio Chu Kang", "Bukit Panjang"],
      // checkboxList: ["Yio Chu Kang", "Bukit Panjang", "Punggol", "Jurong West"],
      // staff: ["1"],
      formDataPrivilege: [
        {
          moduleName: "Staff",
          apps: require("@/assets/images/menu/staff.svg"),
          ismodulePermission: false,
          privilegeList: [
            {
              name: "Staff",
              optionsVal: "View",
              options: ["View", "Create", "Edit", "Full"],
              moduleDesc: {
                View: "View all staff records for a location",
                Create: "add a new staff record",
                Edit: "edit a staff record, reset staff password",
                Full:
                  "reset staff password, delete, archive and restore staff record",
              },
            },
          ],
        },
        {
          moduleName: "Students",
          apps: require("@/assets/images/menu/students.svg"),
          ismodulePermission: false,
          privilegeList: [
            {
              name: "Students",
              optionsVal: "View",
              options: ["View", "Create", "Edit", "Full"],
              moduleDesc: {
                View: "View all students records for a location",
                Create: "enroll a new student record",
                Edit: "edit a student record",
                Full:
                  "delete, defer a student, return a student, withdraw a student, enroll a withdrawn student",
              },
            },
            {
              name: "Students contacts",
              optionsVal: "View",
              options: ["View", "Create", "Edit", "Full"],
              moduleDesc: {
                View: "View all contact info of a student",
                Create: "add all kinds of contact to a student",
                Edit: "edit all info of a contact, reset family password",
                Full:
                  "delete a contact, invite an added family member to the system, resend the invitation email to invite the family member, disconnect a family account with the student",
              },
            },
          ],
        },
        {
          moduleName: "People",
          apps: require("@/assets/images/menu/people.svg"),
          ismodulePermission: false,
          privilegeList: [
            {
              name: "People",
              optionsVal: "View",
              options: ["View"],
              moduleDesc: {
                View: "View all people in a location",
              },
            },
          ],
        },
        {
          moduleName: "Classes",
          apps: require("@/assets/images/menu/classes.svg"),
          ismodulePermission: false,
          privilegeList: [
            {
              name: "Class",
              optionsVal: "View",
              options: ["View", "Create", "Edit", "Full"],
              moduleDesc: {
                View: "View all class records for a location",
                Create: "create a new class",
                Edit: "edit a class info, manage people in a class",
                Full: "delete a class",
              },
            },
            {
              name: "Promote classes",
              optionsVal: "View",
              options: ["View", "Create", "Edit", "Full"],
              moduleDesc: {
                View: "View all class records for a location",
                Create: "",
                Edit: "promote students in a class to another one",
                Full: "",
              },
            },
          ],
        },
        {
          moduleName: "Attendance",
          apps: require("@/assets/images/menu/attendance.svg"),
          ismodulePermission: false,
          privilegeList: [
            {
              name: "Attendance",
              optionsVal: "View",
              options: ["View", "Create", "Edit", "Full"],
              moduleDesc: {
                View: "View student attendance records of a class",
                Create: "add student's attendance record",
                Edit: "edit student's attendance record",
                Full: "delete student attendance record",
              },
            },
			{
			  name: "Check-in Kiosk",
			  optionsVal: "View",
			  options: ["View", "Create", "Edit", "Full"],
			  moduleDesc: {
			    View: "View all check-in record",
			    Create: "check in/out student",
			    Edit: "check in/out student",
			    Full: "check in/out student",
			  }, 
			}
          ],
        },
        {
          moduleName: "Commbook",
          apps: require("@/assets/images/menu/commbook.svg"),
          ismodulePermission: false,
          privilegeList: [
            {
              name: "Messages",
              optionsVal: "View",
              options: ["View", "Create", "Edit", "Full"],
              moduleDesc: {
                View: "View all messages",
                Create: "create a new message and send to seleted parents",
                Edit: "reply a message, edit a message created by own",
                Full: "delete a message created by own",
              },
            },
          ],
        },
        {
          moduleName: "Moments",
          apps: require("@/assets/images/menu/moments.svg"),
          ismodulePermission: false,
          privilegeList: [
            {
              name: "Manage media",
              optionsVal: "View",
              options: ["View", "Create", "Edit", "Full"],
              moduleDesc: {
                View: "View all media in a class",
                Create: "upload media",
                Edit: "edit the info of an unshared media",
                Full: "delete an unshared media",
              },
            },
            {
              name: "Share media",
              optionsVal: "View",
              options: ["View", "Create", "Edit", "Full"],
              moduleDesc: {
                View: "View all media in sharing",
                Create: "share selected media with parents",
                Edit: "cancel the sharing of a pending media",
                Full: "approve or reject to share a pending media",
              },
            },
            {
              name: "Manage albums",
              optionsVal: "View",
              options: ["View", "Create", "Edit", "Full"],
              moduleDesc: {
                View: "View all albums in a class",
                Create: "create albums",
                Edit: "edit basic info of an album",
                Full: "delete an album",
              },
            },
          ],
        },
        {
          moduleName: "Announcements",
          apps: require("@/assets/images/menu/announcement.svg"),
          ismodulePermission: false,
          privilegeList: [
            {
              name: "Announcements",
              optionsVal: "View",
              options: ["View", "Create", "Edit", "Full"],
              moduleDesc: {
                View: "View all announcements",
                Create: "add a new announcement",
                Edit: "edit an announcement",
                Full:
                  "post, delete, recall an announcement, archive, restore an announcement",
              },
            },
          ],
        },
		{
		  moduleName: "Journal",
		  apps: require("@/assets/images/menu/journal.svg"),
		  ismodulePermission: false,
		  privilegeList: [
		    {
		      name: "Manage journals",
		      optionsVal: "View",
		      options: ["View", "Create", "Edit", "Full"],
		      moduleDesc: {
		        View: "View and preview all journals",
		        Create: "create journals",
		        Edit: "edit unshared journals, print and download journals",
		        Full:
		          "delete an unshared journal",
		      },
		    },
			{
			  name: "Share journals",
			  optionsVal: "View",
			  options: ["View", "Create", "Edit", "Full"],
			  moduleDesc: {
			    View: "View all journals in posted",
			    Create: "share selected journal with parents",
			    Edit: "undo a journal submission",
			    Full:
			      "approve or recall a journal submission",
			  },
			}
		  ],
		}
      ],
    };
  },
  computed: {
    userId() {
      return JSON.parse(sessionStorage.getItem("staffInfo")).userId;
    },
  },
  methods: {
    async getData() {
      let params = {
        id: this.userId,
      };

      this.roleId = await Ajax.get("/usermanage/staff/user/selectStaffByUserId", params).then(
        (res) => {
          if (res.code === "0000") {
            return res.data.roleId;
          }
        }
      );
      
      Ajax.get("/usermanage/role/selectRoleDetailByRoleId", {
        roleId: this.roleId,
      }).then((res) => {
        if (res.code === "0000") {
          this.formDataPrivilege.forEach((item) => {
            res.data.rolePermissionList.map((item1) => {
              if (
                item.moduleName === item1.moduleName &&
                item1.hasModule === "1"
              ) {
                item.ismodulePermission = true;
                item.privilegeList.map((item2) => {
                  JSON.parse(item1.permissions).map((item3) => {
                    if (item3.lable === item2.name) {
                      item2.optionsVal = item3.value;
                    }
                  });
                });
              }
            });
          });
        }
      });
    },
  },
  created() {
    this.getData();
  },
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
/deep/ .section-main {
  margin: 0;
  padding: 0;
}
/deep/ .el-dialog__body {
  max-height: 412px;
  overflow-y: auto;
}
.admin-console {
  font-family: Roboto;
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  &-header {
    display: flex;
    align-items: center;
    padding: 20px;
    color: #000;
    background: #f2f2f2;
    &-icon {
      margin-right: 16px;
      width: 24px;
      height: 24px;
    }
  }
  &-list {
    li {
      padding: 12px 62px;
      border-bottom: 1px solid #e0e0e0;
      color: rgba(0, 0, 0, 0.76);
      &:last-child {
        border-bottom: 0;
      }
    }
  }
}
.privileges-default-list{
  li{
    padding: 0 40px;
    height: 50px;
  }
}
/deep/.content .has_breadcrumb{
	padding-bottom: 112px;
}
</style>