<!-- studentInfo -->
<template>
	<div class="content">
		<!--  section-info1 -->
		<section-info1 :data="profileList" :renderPass="is_changed" title="Profile" :iconEdit="pageType === '1' && !$route.query.class && ($buttonPermission('Students', 'Full') ||
            $buttonPermission('Students', 'Edit'))"
		 :readonly.sync="readonly" @submit="submitEdit" @cancel="handleCancel" @input="input" @readonly='changeReadonly' v-loading='loading'>
			<template slot="renderHtml" slot-scope="scope">
				<baseGGSelect v-if="scope.data.key === 'gender'" v-model="scope.data.value" placeholder="Gender" class="float">
					<md-option v-for="(item, index) in genderList" :key="index" :value="item.value">
						{{ item.value }}
					</md-option>
				</baseGGSelect>
				<baseGGSelect v-else-if="scope.data.key === 'race'" v-model="scope.data.value" placeholder="Race" class="float">
					<md-option v-for="(item, index) in raceList" :key="index" :value="item.value">
						{{ item.value }}
					</md-option>
				</baseGGSelect>
				<md-datepicker v-if="scope.data.key === 'dateOfBirth'" v-model="scope.data.value" :readonly="true" :disabled="true"
				 :md-model-type="String" md-immediately @md-closed="computedAge" class="float datepicker-profile">
					<label>Date of birth</label>
				</md-datepicker>
				<baseGGSelect v-else-if="scope.data.key === 'nationality'" v-model="scope.data.value" :placeholder="scope.data.placeholder"
				 class="float">
					<md-option v-for="(item, index) in nationalityList" :key="index" :value="item.value">
						{{ item.value }}
					</md-option>
				</baseGGSelect>
				<baseGGSelect v-else-if="scope.data.key === 'residentialStatus'" v-model="scope.data.value" :placeholder="scope.data.placeholder"
				 class="float">
					<md-option v-for="(item, index) in residentialStatusList" :key="index" :value="item.value">
						{{ item.value }}
					</md-option>
				</baseGGSelect>
				<baseGGSelect v-else-if="scope.data.key === 'idType'" v-model="scope.data.value" placeholder="ID type" class="float">
					<md-option v-for="(item, index) in IDTypeList" :key="index" :value="item.value">
						{{ item.value }}
					</md-option>
				</baseGGSelect>
				<baseGGSelect v-if="scope.data.key === 'IdNo'" v-model="scope.data.value" placeholder="Id No" class="float">
					<md-option v-for="(item, index) in IdNoList" :key="index" :value="item.value">
						{{ item.value }}
					</md-option>
				</baseGGSelect>
				<baseGGSelect v-else-if="scope.data.key === 'primaryLanguage'" v-model="scope.data.value" placeholder="Primary language"
				 class="float">
					<md-option v-for="(item, index) in primaryLanguageList" :key="index" :value="item.value">
						{{ item.value }}
					</md-option>
				</baseGGSelect>
				<baseGGSelect v-else-if="scope.data.key === 'motherTongue'" v-model="scope.data.value" placeholder="Mother tongue"
				 class="float">
					<md-option v-for="(item, index) in motherTongueList" :key="index" :value="item.value">
						{{ item.value }}
					</md-option>
				</baseGGSelect>
			</template>
		</section-info1>
		<!--  section-info2 -->
		<Section title="Home address" class="margin-0" v-loading='loading'>
			<div class="section-content">
				<ul class="section-content-input" v-if="homeAddress.readonly">
					<li class="item" :class="{ edit: !homeAddress.readonly }">
						<div class="label">Address</div>
						<div class="value">
							<div v-if="homeAddress.homeAddres">
								<div>{{ homeAddress.homeAddres }}</div>
							</div>
							<div v-else>-</div>
							<div v-if="homeAddress.homeAddressLine2">
								<div>{{ homeAddress.homeAddressLine2 }}</div>
							</div>
							<div v-else>-</div>
							<div v-if="homeAddress.postalCode">
								<div>{{ homeAddress.postalCode }}</div>
							</div>
							<div v-else>-</div>
						</div>
						<div class="label"></div>
						<div class="value"></div>
					</li>
					<li class="item" :class="{ edit: !homeAddress.readonly }">
						<div class="label">Home number</div>
						<div class="value">
							<div v-if="homeAddress.phone.phoneNumber">
								{{ homeAddress.phone.countryCode }}
								{{ homeAddress.phone.phoneNumber }}
							</div>
							<div v-else>-</div>
						</div>
						<div class="label"></div>
						<div class="value"></div>
					</li>
				</ul>
				<div v-if="!homeAddress.readonly" class="input-content">
					<gg-input v-model="homeAddress.homeAddres" key="address" ref="address" placeholder="Address" class="float" />
					<gg-input v-model="homeAddress.homeAddressLine2" key="homeAddressLine2" ref="homeAddressLine2" placeholder="Address line 2"
					 class="float" />
					<gg-input v-model="homeAddress.postalCode" key="postalCode" ref="postalCode" placeholder="Postal code" class="float" />
					<PhoneNumber v-model="homeAddress.phone" ref="homeNumber" class="float"></PhoneNumber>
				</div>
				<i v-if="homeAddress.readonly && pageType === '1' && !$route.query.class && ($buttonPermission('Students', 'Full') ||
            $buttonPermission('Students', 'Edit'))" class="iconfont iconedit" @click="editHomeAddress"></i>
				<div v-if="!homeAddress.readonly" class="text-right">
					<BaseButton @click="cancleHomeAddress">Cancel</BaseButton>
					<BaseButton @click="submitHomeAddress" type="primary" :disabled='!is_homeChanged'>Save</BaseButton>
				</div>
			</div>
		</Section>
		<Snackbar :visible.sync="snackbar.isShow" :content="snackbar.content" />
	</div>
</template>

<script>
	import moment from 'moment'
	import {
		Ajax,
		Utils
	} from "@/common";
	import {
		mapState
	} from "vuex";
	export default {
		components: {},
		data() {
			return {
				isloading:false,
				profileList: [{
						placeholder: "Nickname",
						key: "nickname",
						value: "",
						autofocus: true,
						float: true,
						// reg: /\S/,
						// errorText: "Nick name is required",
					},
					{
						placeholder: "Alias name",
						key: "aliasName",
						value: "",
						float: true,
						// reg: /\S/,
						// errorText: "Alias name is required",
					},
					{
						placeholder: "Gender",
						value: "",
						type: "select",
						key: "gender",
						selectData: ["Female", "male"],
						errorText: "required",
						renderHtml: true,
					},
					{
						placeholder: "Race",
						value: "",
						type: "select",
						key: "race",
						selectData: [],
						errorText: "required",
						renderHtml: true,
					},
					{
						placeholder: "Date of birth",
						value: "",
						key: "dateOfBirth",
						float: true,
						reg: /\S/,
						errorText: "required",
						renderHtml: true,
					},
					{
						placeholder: "Age",
						value: "",
						key: "age",
						float: true,
						reg: /\S/,
						errorText: "required",
						disabled: true,
					},
					{
						placeholder: "Nationality",
						value: "",
						type: "select",
						key: "nationality",
						selectData: ["Female", "male"],
						errorText: "required",
						renderHtml: true,
					},
					{
						placeholder: "Residential status",
						value: "",
						type: "select",
						key: "residentialStatus",
						errorText: "required",
						renderHtml: true,
					},
					{
						placeholder: "ID type",
						value: "",
						type: "select",
						key: "idType",
						float: true,
						errorText: "required",
						renderHtml: true,
					},
					{
						placeholder: "ID no.",
						value: "",
						type: "input",
						key: "idNo",
						float: true,
						errorText: "required",
					},
					{
						placeholder: "Primary language",
						value: "",
						type: "select",
						key: "primaryLanguage",
						errorText: "required",
						renderHtml: true,
					},
					{
						placeholder: "Mother tongue",
						value: "",
						type: "select",
						key: "motherTongue",
						errorText: "required",
						renderHtml: true,
					},
				],
				homeAddress: {
					readonly: true,
					homeAddres: "",
					homeAddressLine2: "",
					postalCode: "",
					homeNumber: "",
					phone: {
						countryCode: "+65",
						phoneNumber: "",
					},
				},
				renderPass: false,
				renderPass2: true,
				slotData: ["nickname", "aliasName"],
				isDisabled: true,
				readonly: true,
				readonly2: true,
				raceList: [],
				nationalityList: [],
				residentialStatusList: [],
				IDTypeList: [],
				genderList: [],
				IdNoList: [],
				primaryLanguageList: [],
				motherTongueList: [],

				// 0217cy  start 这个参数用来判断当前表单是否修改  在getData里重置为false watch里监听改为true 控制点击编辑按钮时 是否弹窗
				is_changed: false,
				is_homeChanged: false,
				firstData: [],
				firstHomeData: {},
				// end
				snackbar: {
					isShow: false,
					content: "",
				},
				is_start: false,
				rowId: null, // 修改学生状态用到ID
				messageObj: {
					Delete: {
						title: "Delete student ?",
						desc: "Once the student is deleted, you will not be able to recover the student data.",
						cancelTxt: "Cancel",
						confirmTxt: "Delete",
						snackbarContent: "Student has been deleted",
					},
					Deferred: {
						title: "Defer student ?",
						desc: "Once deferred, this student will move to the deferred students. Student data will remain in the system.",
						cancelTxt: "Cancel",
						confirmTxt: "Defer",
						snackbarContent: "Student has been deferred",
					},
					Enrolled: {
						title: "Return student ?",
						desc: "Once returned, This student will move to the enrolled students.",
						cancelTxt: "Cancel",
						confirmTxt: "Return",
						snackbarContent: "Student has been returned",
					},
					Withdrawn: {
						title: "Withdraw student ?",
						desc: "Once Withdrawn, the student will be removed from assigned class. The student's family users will be disconnected with the system.   ",
						cancelTxt: "Cancel",
						confirmTxt: "Withdraw",
						snackbarContent: "Student has been Withdrawn",
					},
				},
				className: sessionStorage.getItem("className"),
				locationName: sessionStorage.getItem("schoolName"),
			};
		},
		filters: {
			firstUpperCase: Utils.firstUpperCase,
		},
		computed: {
			studentId() {
				return JSON.parse(sessionStorage.getItem("studentInfo")).studentId;
			},
			pageType() {
				return JSON.parse(sessionStorage.getItem("studentInfo")).type;
			},
			...mapState({
				user: (state) => state.user,
			}),
		},
		watch: {

			profileList: {
				deep: true,
				handler() {
					let a = []
					this.$nextTick(() => {
						this.profileList.forEach((item, index) => {
							if (this.firstData[index].value == item.value) {

							} else {
								this.is_changed = true
								sessionStorage.setItem('isChanged', JSON.stringify(this.is_changed || this.is_homeChanged))
							}
						})
					});
				},
			},
			homeAddress: {
				deep: true,
				// immediate: true,
				handler() {
					this.$nextTick(() => {
						if (this.homeAddress.homeAddres == this.firstHomeData.homeAddres &&
							this.homeAddress.homeAddressLine2 == this.firstHomeData.homeAddressLine2 &&
							this.homeAddress.postalCode == this.firstHomeData.postalCode &&
							this.homeAddress.phone.countryCode == this.firstHomeData.phone.countryCode &&
							this.homeAddress.phone.phoneNumber == this.firstHomeData.phone.phoneNumber
						) {} else {
							this.is_homeChanged = true
							sessionStorage.setItem('isChanged', JSON.stringify(this.is_changed || this.is_homeChanged))
						}
					});
				},
			},
		},
		beforeRouteLeave(to, from, next) {
			if (JSON.parse(sessionStorage.getItem('isChanged'))) {
				let messageObj = {
					title: "Save changes",
					desc: "You have some unsaved changes. Please save them before you continue.",
					cancelTxt: "",
					confirmTxt: "",
				};
				let callback = () => {
					// this.handleUpdateClass("delete");
				};
				this.openMessageBox(messageObj, callback);
			} else {
				next()
			}
		},
		created() {
			// this.breadcrumbData = this.$store.state.breadcrumb.breadcrumbData;
			this.$material.locale.dateFormat = "dd/MM/yyyy";
			this._getData();
			this._getSettingList("student", "race", "raceList");
			this._getSettingList("student", "nationality", "nationalityList");
			this._getSettingList(
				"general",
				"residentialStatus",
				"residentialStatusList"
			);
			this._getSettingList("student", "primaryLanguage", "primaryLanguageList");
			this._getSettingList("student", "motherTongue", "motherTongueList");
			this._getSettingList("student", "idType", "IDTypeList");
			this._getSettingList("student", "gender", "genderList");
			// 获取idNo
			this._getSettingList("student", "idNo", "IdNoList");
			// console.log(
			// this.$buttonPermission('Students', 'Edit'))
		},
		methods: {
			computedAge() {
				this.$nextTick(() => {
					let dateOfBirth = this.profileList.find(
						(item) => item.key == "dateOfBirth"
					).value;
					let currentYear = new Date().getFullYear();
					let currentMonth = new Date().getMonth() + 1;
					let birthYear = dateOfBirth && Number(dateOfBirth.split("/")[2]);
					let birthMonth = dateOfBirth && Number(dateOfBirth.split("/")[1]);
					let age = currentYear - birthYear;
					let monthTotal = age * 12 + currentMonth - birthMonth;
					if (dateOfBirth !== null) {
						this.firstData.find((item) => item.key == "age").value =
							`${parseInt(
			  monthTotal / 12
			)}y ${monthTotal % 12}m`;
						this.profileList.find((item) => item.key == "age").value =
							`${parseInt(
            monthTotal / 12
          )}y ${monthTotal % 12}m`;

					} else {
						this.firstData.find((item) => item.key == "age").value = "";
						this.profileList.find((item) => item.key == "age").value = "";

					}
				});
			},
			_getData() {
				this.loading = true;
				Ajax.get("/usermanage/student/selectByStuId", {
						stuId: this.studentId,
					})
					.then((res) => {
						let {
							homeAddres,
							homeAddressLine2,
							postalCode,
							country,
							homeNumber,
						} = res.data;
						this.loading = false;
						this.firstData = []
						this.is_changed = false
						this.is_homeChanged = false
						this.homeAddress.readonly = true;
						this.readonly = true;
						let a = {}
						this.profileList.forEach((item) => {
							
							item.value = res.data[item.key];
							if(item.key=='dateOfBirth'){
								if(item.value==null){
									item.value = null
								}else{
									item.value = moment(item.value).format('DD/MM/yyyy')
								}
							}
							a = {
								'key': item.key,
								'value': item.value
							}
							this.firstData.push(a)
						});
						this.computedAge();
						this.homeAddress.homeAddres = homeAddres;
						this.homeAddress.homeAddressLine2 = homeAddressLine2;
						this.homeAddress.postalCode = postalCode;
						this.homeAddress.phone = {
							countryCode: country ? country : "+65",
							phoneNumber: homeNumber ? homeNumber : "",
						};
						this.firstHomeData = {};
						this.firstHomeData.homeAddres = homeAddres;
						this.firstHomeData.homeAddressLine2 = homeAddressLine2;
						this.firstHomeData.postalCode = postalCode;
						this.firstHomeData.phone = {
							countryCode: country ? country : "+65",
							phoneNumber: homeNumber ? homeNumber : "",
						};
						this.rowId = res.data.id;
						sessionStorage.setItem('isChanged', JSON.stringify(this.is_changed || this.is_homeChanged))
					})
					.catch(() => {
						this.loading = false;
					});
			},
			_getSettingList(dataLevel, dataType, datalistName) {
				let data = {
					userId: this.user.userId,
					dataLevel,
					dataType,
				};
				this.loading = true;
				Ajax.post("/usermanage/setting/getList", data)
					.then((res) => {
						let response = res.data;
						this.loading = false;
						this[datalistName] = response || [];
					})
					.catch(() => {
						this.loading = false;
					});
			},
			editHomeAddress() {
				if (!this.is_changed) {
					this.readonly = true
					this.homeAddress.readonly = false;
				} else {
					let messageObj = {
						title: "Save changes",
						desc: "You have some unsaved changes. Please save them before you continue.",
						cancelTxt: "",
						confirmTxt: "",
					};
					let callback = () => {
						// this.handleUpdateClass("delete");
					};
					this.openMessageBox(messageObj, callback);
				}

			},
			cancleHomeAddress() {
				this._getData()
			},
			submitHomeAddress() {
				let params = {
					homeAddres: this.homeAddress.homeAddres,
					homeAddressLine2: this.homeAddress.homeAddressLine2,
					postalCode: this.homeAddress.postalCode,
					country: this.homeAddress.phone.countryCode,
					homeNumber: this.homeAddress.phone.phoneNumber,
					id: this.studentId,
				};
				this.loading = true;
				Ajax.post("/usermanage/student/updateStudent", params).then((res) => {
					if (res.code === "0000") {
						this.homeAddress.readonly = true;
						this.is_homeChanged = false
						this.handleSuspend("Student has been edited");
					} else {
						this.handleSuspend(res.message);
					}
					this.loading = false;
					this._getData();
				});
			},
			_updateStudent() {
				let formData = {
					nickname: "",
					aliasName: "",
					gender: "",
					race: "",
					dateOfBirth: "",
					nationality: "",
					residentialStatus: "",
					idType: "",
					idNo: "",
					primaryLanguage: "",
					motherTongue: "",
					id: this.studentId
				};
				// homeAddres: this.homeAddress.homeAddres,
				// homeAddressLine2: this.homeAddress.homeAddressLine2,
				// postalCode: this.homeAddress.postalCode,
				// country: this.homeAddress.phone.countryCode,
				// homeNumber: this.homeAddress.phone.phoneNumber,
				this.profileList.forEach((item) => {
					if (item.key !== "age") {
						formData[item.key] = item.value;
					}
				});
				if(formData.dateOfBirth){
					console.log(formData.dateOfBirth)
					
					formData.dateOfBirth=moment(formData.dateOfBirth,['DD/MM/yyyy','yyyy-MM-DD']).format('yyyy-MM-DD')
				}
				
				this.loading = true;
				Ajax.post("/usermanage/student/updateStudent", formData)
					.then((res) => {
						if (res.code !== "0000") {
							this.handleSuspend(res.message);
						}
						this.handleSuspend("Student has been edited");
						this.loading = false;
						this._getData();
					})
					.catch(() => {
						this.loading = false;
					});
			},
			openMessageBox(messageObj, callback) {
				this.$confirm(messageObj.desc, messageObj.title, {
					cancelButtonText: messageObj.cancelTxt,
					confirmButtonText: messageObj.confirmTxt,
					cancelButtonClass: "gg_cancel_button",
					confirmButtonClass: "gg_submit_button",
					customClass: "gg_confirm",
					showCancelButton: false, // 不显示取消按钮
					showConfirmButton: false,

					callback: (action) => {
						if (action == "confirm") {
							callback(action);
						}
					},
				}).then(() => {
					this.handleSuspend(messageObj.snackbarContent);
				});
			},
			handleSuspend(content) {
				this.snackbar.isShow = true;
				this.snackbar.content = content;
			},
			input(res) {
				console.log('输入')
				console.log(res);
			},
			handleCancel() {
				// this.is_changed = false
				// this.readonly = true;
				this._getData()
			},
			submitEdit() {
				this.readonly = true;
				this._updateStudent();
			},
			changeReadonly() {
				if (!this.is_homeChanged) {
					this.readonly = false
					this.homeAddress.readonly = true;
				} else {
					let messageObj = {
						title: "Save changes",
						desc: "You have some unsaved changes. Please save them before you continue.",
						cancelTxt: "",
						confirmTxt: "",
					};
					let callback = () => {
						// this.handleUpdateClass("delete");
					};
					this.openMessageBox(messageObj, callback);
				}
			},
		},
	};
</script>
<style lang='less' scoped>
	//@import url(); 引入公共css类
	/deep/.md-button.md-icon-button.md-dense.md-clear {
		display: none;
	}

	/deep/ .section-main {
		padding: 0;
		
	}
	/deep/.section-content li.item{
		font-family: Roboto;
	}
	/deep/.section-content li.item .label{
		font-family: Roboto-medium;
	}
	/deep/ .el-dialog__body {
		max-height: 412px;
		overflow-y: auto;
	}

	.content {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		// padding: 20px;
		/deep/.float {
			width: calc(50% - 10px);
			flex-basis: calc(50% - 10px);
			flex-grow: 0;
			flex-shrink: 0;
		}
	}

	/deep/.datepicker-profile {
		.md-icon-image {
			position: absolute;
			right: 0;
			margin: 2px auto 0;
		}

		/deep/.md-clear {
			right: 24px !important;
			top: auto;
			bottom: 3px;

			/deep/.md-icon-image {
				position: relative;
				right: auto;
				margin: 4px auto;
			}
		}

		/deep/ .md-input {
			margin-left: 0;
			color: #202124;
			font-family: Roboto;
		}

		/deep/ label {
			left: 0;
			font-size: 14px;
			color: #b4b4b4;
			font-family: Roboto;
		}
	}

	/deep/ .phone-number-content {
		padding-top: 20px;
		padding-bottom: 0;
	}

	/deep/.el-message-box__close .el-icon-close {
		display: none;
	}
	/deep/.md-field{
		    margin-bottom: 20px;
	}
	
	/deep/.md-field label{
		left: 0;
		color: rgba(0,0,0,0.3);
		font-family: Roboto;
		font-size: 14px !important;
	}
	/deep/.md-field.md-clearable .md-input{
		font-family: Roboto;
		margin-left: 0;
		font-size: 14px !important;
	}
	
</style>
